<template>
  <footer class="mt-auto h-auto bg-neutral-high-ultra tracking-[0.02em]">
    <div class="flex px-4 pt-14 max-lg:flex-col lg:p-20 lg:pb-0">
      <figure class="mx-auto mb-8 lg:mb-0 lg:w-[260px]">
        <img loading="lazy" :src="props.footer.logo.url" :alt="props.footer.logo.alt" />
      </figure>
      <div class="flex w-full flex-col lg:ml-4">
        <div class="grid w-full grid-cols-2 gap-x-8 lg:flex lg:flex-row lg:gap-20">
          <template v-if="fullFooter">
            <nav class="order-3 max-lg:mt-4 lg:order-1">
              <h4 class="mb-3 text-nowrap text-lg font-medium leading-5 text-neutral-low-light">
                {{ props.footer.menu.home.title }}
              </h4>
              <ul class="text-md">
                <li v-for="item in props.footer.menu.home.items" :key="item" class="mb-2">
                  <a
                    :href="item.url"
                    :target="item.target"
                    :title="item.label"
                    class="text-neutral-low-ultra transition-colors hover:text-neutral-low-light"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </nav>
            <nav class="order-5 max-lg:mt-4 lg:order-2">
              <h4 class="mb-3 text-nowrap text-lg font-medium leading-5 text-neutral-low-light">
                {{ props.footer.menu.materials.title }}
              </h4>
              <ul class="text-md">
                <li v-for="item in props.footer.menu.materials.items" :key="item" class="mb-2">
                  <a
                    :href="item.url"
                    :target="item.target"
                    :title="item.label"
                    class="text-neutral-low-ultra transition-colors hover:text-neutral-low-light"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </nav>
            <nav class="order-4 max-lg:mt-4 lg:order-3">
              <h4 class="mb-3 text-nowrap text-lg font-medium leading-5 text-neutral-low-light">
                {{ props.footer.menu.segments.title }}
              </h4>
              <ul class="text-md">
                <li v-for="item in props.footer.menu.segments.items" :key="item" class="mb-2">
                  <a
                    :href="item.url"
                    :target="item.target"
                    :title="item.label"
                    class="text-neutral-low-ultra transition-colors hover:text-neutral-low-light"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
              <nav class="hidden max-lg:mt-4 lg:block">
                <h4 class="mb-3 text-nowrap text-lg font-medium leading-5 text-neutral-low-light lg:mt-6">
                  {{ props.footer.menu.locality.title }}
                </h4>
                <ul class="text-md">
                  <li v-for="item in props.footer.menu.locality.items" :key="item" class="mb-2">
                    <a
                      :href="item.url"
                      :target="item.target"
                      :title="item.label"
                      class="text-neutral-low-ultra transition-colors hover:text-neutral-low-light"
                      >{{ item.label }}</a
                    >
                  </li>
                </ul>
              </nav>
            </nav>

            <nav class="order-6 max-lg:mt-4 lg:hidden">
              <h4 class="mb-3 text-nowrap text-lg font-medium leading-5 text-neutral-low-light lg:mt-6">
                {{ props.footer.menu.locality.title }}
              </h4>
              <ul class="text-md">
                <li v-for="item in props.footer.menu.locality.items" :key="item" class="mb-2">
                  <a
                    :href="item.url"
                    :target="item.target"
                    :title="item.label"
                    class="text-neutral-low-ultra transition-colors hover:text-neutral-low-light"
                    >{{ item.label }}</a
                  >
                </li>
              </ul>
            </nav>
          </template>
          <nav
            class="order-1 col-span-2 mt-16 text-center lg:order-4 lg:col-span-1 lg:ml-auto lg:mr-7 lg:mt-0 lg:text-left"
          >
            <a
              :href="props.footer.menu.wantHelp.url"
              :target="props.footer.menu.wantHelp.target"
              class="relative mb-3 block flex-nowrap text-base font-bold leading-5 text-neutral-low-light"
            >
              {{ props.footer.menu.wantHelp.title }}
              <img
                loading="lazy"
                class="w- absolute -right-3 -mt-4 hidden lg:-right-[25px] lg:inline"
                :src="props.footer.menu.wantHelp.image.url"
                :class="props.footer.menu.wantHelp.css"
                :alt="props.footer.menu.wantHelp.image.alt"
                :width="42"
              />
            </a>
            <div>
              <ul>
                <li v-for="item in props.footer.menu.wantHelp.items" :key="item">
                  <p class="mb-2 text-md leading-3 text-neutral-low-ultra transition-colors">
                    {{ item.label }}
                    <template v-if="isMobile && item.link.href != ''">
                      <a :href="item.link.href" target="_blank" rel="noopener noreferrer">
                        <Button
                          :label="item.link.label"
                          icon="uil uil-whatsapp opacity-[.35]"
                          icon-pos="left"
                          class="mb-4 !bg-status-positive-light2"
                        />
                      </a>
                    </template>
                    <template v-else>
                      <p class="flex items-center justify-center lg:justify-start">
                        <i class="uil uil-envelope-alt mr-2 text-[#BCC3CD] lg:hidden"></i>
                        {{ item.link.label }}
                      </p>
                    </template>
                  </p>
                </li>
              </ul>
            </div>
          </nav>
          <nav
            class="order-2 col-span-2 col-start-auto mb-16 ml-auto mt-10 w-full text-center lg:order-5 lg:col-span-1 lg:mb-0 lg:ml-0 lg:mt-0 lg:w-auto lg:text-left"
            :class="!fullFooter && '!mb-7'"
          >
            <h4 class="mb-3 text-nowrap text-base font-medium text-neutral-low-light lg:text-md lg:font-normal">
              {{ props.footer.menu.social.title }}
            </h4>
            <ul class="text-md">
              <li v-for="item in props.footer.menu.social.items" :key="item" class="mr-4 inline last:mr-0">
                <a :href="item.url" :target="item.target" :title="item.label" class="text-neutral-low-ultra"
                  ><i
                    class="text-5xl text-[#465161] transition-colors hover:text-neutral-low-light lg:text-3xl lg:text-black"
                    :class="item.icon"
                /></a>
              </li>
            </ul>
          </nav>
        </div>
        <div v-if="fullFooter" class="mt-16">
          <h5 class="mb-3 text-md text-neutral-low-light">
            {{ props.footer.payments.title }}
          </h5>
          <ul class="max-lg:flex">
            <li v-for="item in props.footer.payments.items" :key="item" class="mr-4 inline">
              <img loading="lazy" class="inline" :src="item.image.url" :alt="item.image.alt" />
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="mt-14 flex flex-col-reverse justify-between border-t border-solid px-4 py-8 text-md leading-5 text-neutral-low-ultra max-lg:pb-16 lg:mb-16 lg:flex-row lg:px-20 lg:py-11"
      :class="!fullFooter && '!mt-0 lg:!mb-0 lg:!mt-7'"
    >
      <div :class="!fullFooter && !isMobile && 'flex'">
        <small class="text-md">
          {{ props.footer.copyright }}
        </small>
        <p v-if="!fullFooter" :class="isMobile && 'inline'">&nbsp;|&nbsp;</p>
        <address class="not-italic">
          {{ props.footer.address }}
        </address>
      </div>
      <ul class="mb-6 mt-6 flex justify-between lg:mb-0 lg:mt-0">
        <li v-for="item in props.footer.menu.legal.items" :key="item" class="mr-11 inline last:mr-0">
          <a
            class="inline text-md text-neutral-low-ultra transition-colors hover:text-neutral-low-light"
            :href="item.url"
            :title="item.label"
            :target="item.target"
          >
            <span v-if="item.click" @click.prevent="handleClick(item.click)">
              {{ item.label }}
            </span>
            <template v-else>
              {{ item.label }}
            </template>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script setup>
import { useIsMobile } from "@/hooks";

const { isMobile } = useIsMobile();

const props = defineProps({
  footer: Object,
  fullFooter: {
    type: Boolean,
    default: false,
  },
});

const handleClick = (clickEvent) => {
  if (clickEvent) {
    const [functionName, argument] = parseClickEvent(clickEvent);
    if (functionName) {
      if (typeof window[functionName] === "function") {
        window[functionName](argument);
      } else {
        console.error(`A função ${functionName} não foi encontrada.`);
      }
    }
  }
};

const parseClickEvent = (clickEvent) => {
  const [functionName, argument] = clickEvent.slice(0, -1).split("(");
  return [functionName.trim(), argument.slice(1, -1)];
};
</script>

<style lang="scss" scoped></style>
